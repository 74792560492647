import React from 'react'
import { Box } from 'rebass'
import './avatar.css'

export const Avatar = props => {
  const size = 96

  const innerBoxShadow = `
    inset 0 10px 20px  0px hsla(0, 0%, 100%, 0.5),
    inset 0  5px 10px  0px hsla(0, 0%, 100%, 0.4),
    inset 0  -5px 5px -2px hsla(0, 0%, 100%, 0.4),
  `

  const boxShadow = `
    0 10px 20px   0px hsla(0, 0%, 0%, 0.1),
    0 10px 20px -10px hsla(0, 0%, 0%, 0.2),
    0  5px 10px  -5px hsla(0, 0%, 0%, 0.1),
    0  5px  5px   0px hsla(0, 0%, 0%, 0.05)
  `

  return (
    <Box
      className="avatar"
      bg="fdgBeige"
      css={{
        width: size,
        height: size,
        borderRadius: '50%',
        boxShadow: innerBoxShadow + boxShadow,
      }}
      {...props}
    />
  )
}
