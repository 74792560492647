import React from 'react'
import { Flex, Text } from 'rebass'
import { Avatar } from '../components/avatar'
import { Head } from '../components/head'

const About = () => (
  <>
    <Head title="À propos" />

    <Flex flexDirection="column" alignItems="center">
      <Avatar mb={3} />

      <Text
        fontFamlily="body"
        css={{
          maxWidth: '50ch',
        }}
      >
        Lorem ipsum dolor set. Lorem ipsum dolor set. Lorem ipsum dolor set.
        Lorem ipsum dolor set. Lorem ipsum dolor set. Lorem ipsum dolor set.
        Lorem ipsum dolor set.
      </Text>
    </Flex>
  </>
)

export default React.memo(About)
